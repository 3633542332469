
import { computed, defineComponent, onUpdated, ref } from "vue";
import { LoadFilterObjects, LoadPanel } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import Cards from "@/components/base/common/Cards.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Swal from "sweetalert2";
import ProviderService from "@/core/services/ProviderService";
import Row from "@/components/base/common/Row.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "CompleteProviders",
  components: {DateTimeFormat, Row, QuickAction, Cards},
  props: {
    cazeId: {}
  },
  emits:['showDetail','clearDetail','complete'],
  setup(props) {
    onUpdated(() => {
      const link = document?.getElementById("main-overview")
      console.log(link);
      if (link) {
        link.classList.remove("active")
      }
    })
    const filterObject = ref({cazeId: props.cazeId, status: 'COMPLETED'})
    const providerPage = computed(() => store.state.ProviderModule.completePage);
    return {
      ...LoadFilterObjects(Actions.LOAD_COMPLETED_PROVIDERS, filterObject.value, ['diag1', 'diag2', 'diag3', 'diag4']),
      providerPage,
      ...LoadPanel(),
    }
  },
  methods: {
    showDetails(id) {
      this.$emit('showDetail', id)
    },
    complete(id) {
      this.$emit('complete', id)
    },
    onDelete(provider) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        heightAuto: false,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          await ProviderService.delete(provider.id).then(() => {
            this.paginationLoad();
            this.$emit('clearDetail');
          })
        }
      })
    }
  }
})
