
import {defineComponent, onRenderTracked, ref} from "vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import AllProviders from "@/views/case/provider/AllProviders.vue";
import ActiveProviders from "@/views/case/provider/ActiveProviders.vue";
import CompleteProviders from "@/views/case/provider/CompletedProviders.vue";
import {ProviderDetail} from "@/core/composite/composite";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import FormNote from "@/views/activity/FormNote.vue";
import Notes from "@/views/note/Notes.vue";
import Row from "@/components/base/common/Row.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Timelines from "@/views/timeline/Timelines.vue";
import ProviderService from "@/core/services/ProviderService";

export default defineComponent({
  name: "Providers",
  props: {
    id: {}
  },
  components: {
    Timelines,
    DateTimeFormat,
    Row, Notes, FormNote, BaseModal, CompleteProviders, ActiveProviders, AllProviders, QuickAction
  },
  setup() {
    const note = ref({text: '', providerId: ''})
    onRenderTracked(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })
    return {
      note,
      ...ProviderDetail(),
    }
  },
  methods: {
    onSaveNote(res: any) {
      const baseModal = this.$refs.noteForm as typeof BaseModal
      baseModal.hideBaseModal()
      const notes = this.$refs.refNotes as typeof Notes
      notes.paginationLoad();
      this.loadDetail(res);
    },
    complete(id) {
      ProviderService.complete(id).then(() => {
        const all = this.$refs.allProviderRef as typeof AllProviders;
        all.paginationLoad();
        const active = this.$refs.activeProvidersRef as typeof ActiveProviders;
        active.paginationLoad();
        const com = this.$refs.completeProviderRef as typeof CompleteProviders;
        com.paginationLoad();
      })
    }
  }
})
